const sitesMap = new Map();
sitesMap.set("default", { siteName: "website", lang: "en", country: "int" });
sitesMap.set("at/en", { siteName: "cochlear-at-en", lang: "en", country: "at" });
sitesMap.set("at/de", { siteName: "cochlear-at-de", lang: "de-AT", country: "at" });
sitesMap.set("au/en", { siteName: "cochlear-au-en", lang: "en", country: "au" });
sitesMap.set("be/en", { siteName: "cochlear-be-en", lang: "en", country: "be" });
sitesMap.set("be/fr", { siteName: "cochlear-be-fr", lang: "fr-BE", country: "be" });
sitesMap.set("be/nl", { siteName: "cochlear-be-nl", lang: "nl-BE", country: "be" });
sitesMap.set("br/en", { siteName: "cochlear-br-en", lang: "en", country: "br" });
sitesMap.set("br/pt", { siteName: "cochlear-br-pt", lang: "pt-BR", country: "br" });
sitesMap.set("ca/en", { siteName: "cochlear-ca-en", lang: "en", country: "ca" });
sitesMap.set("ca/fr", { siteName: "cochlear-ca-fr", lang: "fr-CA", country: "ca" });
sitesMap.set("ch/en", { siteName: "cochlear-ch-de-en", lang: "en", country: "ch" });
sitesMap.set("ch/de", { siteName: "cochlear-ch-de-de", lang: "de-CH", country: "ch" });
sitesMap.set("ch/ef", { siteName: "cochlear-ch-fr-en", lang: "en", country: "ch" });
sitesMap.set("ch/fr", { siteName: "cochlear-ch-fr-fr", lang: "fr-CH", country: "ch" });
sitesMap.set("cz/en", { siteName: "cochlear-cz-en", lang: "en", country: "cz" });
sitesMap.set("cz/cs", { siteName: "cochlear-cz-cs", lang: "cs-CZ", country: "cz" });
sitesMap.set("de/en", { siteName: "cochlear-de-en", lang: "en", country: "de" });
sitesMap.set("de/de", { siteName: "cochlear-de-de", lang: "de-DE", country: "de" });
sitesMap.set("dk/en", { siteName: "cochlear-dk-en", lang: "en", country: "dk" });
sitesMap.set("dk/da", { siteName: "cochlear-dk-da", lang: "da-DK", country: "dk" });
sitesMap.set("es/en", { siteName: "cochlear-es-en", lang: "en", country: "es" });
sitesMap.set("es/es", { siteName: "cochlear-es-es", lang: "es-ES", country: "es" });
sitesMap.set("fi/en", { siteName: "cochlear-fi-en", lang: "en", country: "fi" });
sitesMap.set("fi/fi", { siteName: "cochlear-fi-fi", lang: "fi-FI", country: "fi" });
sitesMap.set("fr/en", { siteName: "cochlear-fr-en", lang: "en", country: "fr" });
sitesMap.set("fr/fr", { siteName: "cochlear-fr-fr", lang: "fr-FR", country: "fr" });
sitesMap.set("hk/en", { siteName: "cochlear-hk-en", lang: "en", country: "hk" });
sitesMap.set("hk/zh", { siteName: "cochlear-hk-zh", lang: "zh-HK", country: "hk" });
sitesMap.set("hu/en", { siteName: "cochlear-hu-en", lang: "en", country: "hu" });
sitesMap.set("hu/hu", { siteName: "cochlear-hu-hu", lang: "hu-HU", country: "hu" });
sitesMap.set("id/en", { siteName: "cochlear-id-en", lang: "en", country: "id" });
sitesMap.set("id/id", { siteName: "cochlear-id-id", lang: "id-ID", country: "id" });
sitesMap.set("il/en", { siteName: "cochlear-il-en", lang: "en", country: "il" });
sitesMap.set("il/he", { siteName: "cochlear-il-he", lang: "he-IL", country: "il" });
sitesMap.set("in/en", { siteName: "cochlear-in-en", lang: "en", country: "in" });
sitesMap.set("in/hi", { siteName: "cochlear-in-hi", lang: "hi-IN", country: "in" });
sitesMap.set("in/ml", { siteName: "cochlear-in-ml", lang: "ml-IN", country: "in" });
sitesMap.set("it/en", { siteName: "cochlear-it-en", lang: "en", country: "it" });
sitesMap.set("it/it", { siteName: "cochlear-it-it", lang: "it-IT", country: "it" });
sitesMap.set("jp/en", { siteName: "cochlear-jp-en", lang: "en", country: "jp" });
sitesMap.set("jp/ja", { siteName: "cochlear-jp-ja", lang: "ja-JP", country: "jp" });
sitesMap.set("kr/en", { siteName: "cochlear-kr-en", lang: "en", country: "kr" });
sitesMap.set("kr/ko", { siteName: "cochlear-kr-ko", lang: "ko-KR", country: "kr" });
sitesMap.set("lk/en", { siteName: "cochlear-lk-en", lang: "en", country: "lk" });
sitesMap.set("la/en", { siteName: "cochlear-la-en", lang: "en", country: "la" });
sitesMap.set("la/es", { siteName: "cochlear-la-es", lang: "es-AR", country: "la" });
sitesMap.set("me/en", { siteName: "cochlear-me-en", lang: "en", country: "me" });
sitesMap.set("me/ar", { siteName: "cochlear-me-ar", lang: "ar-AE", country: "me" });
sitesMap.set("me/fr", { siteName: "cochlear-me-fr", lang: "fr-MA", country: "me" });
sitesMap.set("my/en", { siteName: "cochlear-my-en", lang: "en", country: "my" });
sitesMap.set("nl/en", { siteName: "cochlear-nl-en", lang: "en", country: "nl" });
sitesMap.set("nl/nl", { siteName: "cochlear-nl-nl", lang: "nl-NL", country: "nl" });
sitesMap.set("no/en", { siteName: "cochlear-no-en", lang: "en", country: "no" });
sitesMap.set("no/no", { siteName: "cochlear-no-nb", lang: "nb-NO", country: "no" });
sitesMap.set("nz/en", { siteName: "cochlear-nz-en", lang: "en", country: "nz" });
sitesMap.set("oee/en", { siteName: "cochlear-oee-en", lang: "en", country: "oee" });
sitesMap.set("oee/ru", { siteName: "cochlear-oee-ru", lang: "ru-RU", country: "oee" });
sitesMap.set("pk/en", { siteName: "cochlear-pk-en", lang: "en", country: "pk" });
sitesMap.set("ph/en", { siteName: "cochlear-ph-en", lang: "en", country: "ph" });
sitesMap.set("pl/en", { siteName: "cochlear-pl-en", lang: "en", country: "pl" });
sitesMap.set("pl/pl", { siteName: "cochlear-pl-pl", lang: "pl-PL", country: "pl" });
sitesMap.set("ro/en", { siteName: "cochlear-ro-en", lang: "en", country: "ro" });
sitesMap.set("ro/ro", { siteName: "cochlear-ro-ro", lang: "ro-RO", country: "ro" });
sitesMap.set("ru/en", { siteName: "cochlear-ru-en", lang: "en", country: "ru" });
sitesMap.set("ru/ru", { siteName: "cochlear-ru-ru", lang: "ru-RU", country: "ru" });
sitesMap.set("sg/en", { siteName: "cochlear-sg-en", lang: "en", country: "sg" });
sitesMap.set("sk/en", { siteName: "cochlear-sk-en", lang: "en", country: "sk" });
sitesMap.set("sk/sk", { siteName: "cochlear-sk-sk", lang: "sk-SK", country: "sk" });
sitesMap.set("sv/en", { siteName: "cochlear-sv-en", lang: "en", country: "sv" });
sitesMap.set("sv/se", { siteName: "cochlear-sv-se", lang: "sv-SE", country: "sv" });
sitesMap.set("th/en", { siteName: "cochlear-th-en", lang: "en", country: "th" });
sitesMap.set("th/th", { siteName: "cochlear-th-th", lang: "th-TH", country: "th" });
sitesMap.set("tr/en", { siteName: "cochlear-tr-en", lang: "en", country: "tr" });
sitesMap.set("tr/tr", { siteName: "cochlear-tr-tr", lang: "tr-TR", country: "tr" });
sitesMap.set("tw/en", { siteName: "cochlear-tw-en", lang: "en", country: "tw" });
sitesMap.set("tw/zh", { siteName: "cochlear-tw-zh", lang: "zh-TW", country: "tw" });
sitesMap.set("uk/en", { siteName: "cochlear-uk-en", lang: "en", country: "uk" });
sitesMap.set("us/en", { siteName: "cochlear-us-en", lang: "en", country: "us" });
sitesMap.set("us/es", { siteName: "cochlear-us-es", lang: "es-MX", country: "us" });
sitesMap.set("vn/en", { siteName: "cochlear-vn-en", lang: "en", country: "vn" });
sitesMap.set("vn/vi", { siteName: "cochlear-vn-vi", lang: "vi-VN", country: "vn" });

// non standard
sitesMap.set("emea/en", { siteName: "cochlear-emea-en", lang: "en", country: "emea" });
sitesMap.set("mdr/en", { siteName: "cochlear-mdr-en", lang: "en", country: "mdr" });
sitesMap.set("apac/en", { siteName: "cochlear-apac-en", lang: "en", country: "apac" });
//global site
sitesMap.set("global/en", { siteName: "cochlear-global-en", lang: "en", country: "global" });
sitesMap.set("global/ar", { siteName: "cochlear-global-ar", lang: "ar-AE", country: "global" });
sitesMap.set("global/az", { siteName: "cochlear-global-az", lang: "az-Latn-AZ", country: "global" });
sitesMap.set("global/bg", { siteName: "cochlear-global-bg", lang: "bg-BG", country: "global" });
sitesMap.set("global/zh-hans", { siteName: "cochlear-global-zh-hans", lang: "zh-CN", country: "global" });
sitesMap.set("global/zh-hant", { siteName: "cochlear-global-zh-hant", lang: "zh-TW", country: "global" });
sitesMap.set("global/hr", { siteName: "cochlear-global-hr", lang: "hr-HR", country: "global" });
sitesMap.set("global/cs", { siteName: "cochlear-global-cs", lang: "cs-CZ", country: "global" });
sitesMap.set("global/da", { siteName: "cochlear-global-da", lang: "da-DK", country: "global" });
sitesMap.set("global/nl", { siteName: "cochlear-global-nl", lang: "nl-NL", country: "global" });
sitesMap.set("global/et", { siteName: "cochlear-global-et", lang: "et-EE", country: "global" });
sitesMap.set("global/fa", { siteName: "cochlear-global-fa", lang: "fa-IR", country: "global" });
sitesMap.set("global/fi", { siteName: "cochlear-global-fi", lang: "fi-FI", country: "global" });
sitesMap.set("global/fr", { siteName: "cochlear-global-fr", lang: "fr-FR", country: "global" });
sitesMap.set("global/de", { siteName: "cochlear-global-de", lang: "de-DE", country: "global" });
sitesMap.set("global/el", { siteName: "cochlear-global-el", lang: "el-GR", country: "global" });
sitesMap.set("global/he", { siteName: "cochlear-global-he", lang: "he-IL", country: "global" });
sitesMap.set("global/hu", { siteName: "cochlear-global-hu", lang: "hu-HU", country: "global" });
sitesMap.set("global/is", { siteName: "cochlear-global-is", lang: "is-IS", country: "global" });
sitesMap.set("global/id", { siteName: "cochlear-global-id", lang: "id-ID", country: "global" });
sitesMap.set("global/it", { siteName: "cochlear-global-it", lang: "it-IT", country: "global" });
sitesMap.set("global/ja", { siteName: "cochlear-global-ja", lang: "ja-JP", country: "global" });
sitesMap.set("global/ka", { siteName: "cochlear-global-ka", lang: "ka-GE", country: "global" });
sitesMap.set("global/kk", { siteName: "cochlear-global-kk", lang: "kk-KZ", country: "global" });
sitesMap.set("global/ko", { siteName: "cochlear-global-ko", lang: "ko-KR", country: "global" });
sitesMap.set("global/lv", { siteName: "cochlear-global-lv", lang: "lv-LV", country: "global" });
sitesMap.set("global/lt", { siteName: "cochlear-global-lt", lang: "lt-LT", country: "global" });
sitesMap.set("global/ms", { siteName: "cochlear-global-ms", lang: "ms-MY", country: "global" });
sitesMap.set("global/no", { siteName: "cochlear-global-no", lang: "nb-NO", country: "global" });
sitesMap.set("global/pl", { siteName: "cochlear-global-pl", lang: "pl-PL", country: "global" });
sitesMap.set("global/pt", { siteName: "cochlear-global-pt", lang: "pt-PT", country: "global" });
sitesMap.set("global/pt-br", { siteName: "cochlear-global-pt-br", lang: "pt-BR", country: "global" });
sitesMap.set("global/ro", { siteName: "cochlear-global-ro", lang: "ro-RO", country: "global" });
sitesMap.set("global/ru", { siteName: "cochlear-global-ru", lang: "ru-RU", country: "global" });
sitesMap.set("global/sr", { siteName: "cochlear-global-sr", lang: "sr", country: "global" });
sitesMap.set("global/sk", { siteName: "cochlear-global-sk", lang: "sk-SK", country: "global" });
sitesMap.set("global/sl", { siteName: "cochlear-global-sl", lang: "sl-SI", country: "global" });
sitesMap.set("global/sq", { siteName: "cochlear-global-sq", lang: "sq-AL", country: "global" });
sitesMap.set("global/es", { siteName: "cochlear-global-es", lang: "es-ES", country: "global" });
sitesMap.set("global/sv", { siteName: "cochlear-global-sv", lang: "sv-SE", country: "global" });
sitesMap.set("global/th", { siteName: "cochlear-global-th", lang: "th-TH", country: "global" });
sitesMap.set("global/tr", { siteName: "cochlear-global-tr", lang: "tr-TR", country: "global" });
sitesMap.set("global/uk", { siteName: "cochlear-global-uk", lang: "uk-UA", country: "global" });
sitesMap.set("global/vi", { siteName: "cochlear-global-vi", lang: "vi-VN", country: "global" });
sitesMap.set("global/mk", { siteName: "cochlear-global-mk", lang: "mk-MK", country: "global" });
sitesMap.set("global/uz", { siteName: "cochlear-global-uz", lang: "uz-Latn-UZ", country: "global" });

// CIOM sites
sitesMap.set("ciom/en", { siteName: "cochlear-ciom-en", lang: "en", country: "ciom" });
sitesMap.set("ciom/da", { siteName: "cochlear-ciom-da", lang: "da-DK", country: "ciom" });
sitesMap.set("ciom/nl", { siteName: "cochlear-ciom-nl", lang: "nl-NL", country: "ciom" });
sitesMap.set("ciom/fi", { siteName: "cochlear-ciom-fi", lang: "fi-FI", country: "ciom" });
sitesMap.set("ciom/fr", { siteName: "cochlear-ciom-fr", lang: "fr-FR", country: "ciom" });
sitesMap.set("ciom/de", { siteName: "cochlear-ciom-de", lang: "de-DE", country: "ciom" });
sitesMap.set("ciom/it", { siteName: "cochlear-ciom-it", lang: "it-IT", country: "ciom" });
sitesMap.set("ciom/no", { siteName: "cochlear-ciom-no", lang: "nb-NO", country: "ciom" });
sitesMap.set("ciom/pl", { siteName: "cochlear-ciom-pl", lang: "pl-PL", country: "ciom" });
sitesMap.set("ciom/pt-br", { siteName: "cochlear-ciom-pt-br", lang: "pt-BR", country: "ciom" });
sitesMap.set("ciom/ru", { siteName: "cochlear-ciom-ru", lang: "ru-RU", country: "ciom" });
sitesMap.set("ciom/es", { siteName: "cochlear-ciom-es", lang: "es-ES", country: "ciom" });
sitesMap.set("ciom/sv", { siteName: "cochlear-ciom-sv", lang: "sv-SE", country: "ciom" });
sitesMap.set("ciom/tr", { siteName: "cochlear-ciom-tr", lang: "tr-TR", country: "ciom" });

sitesMap.set("mri-us/en", { siteName: "cochlear-mri-us", lang: "en", country: "us" });

//brand-hub
sitesMap.set("brand-hub", { siteName: "brand-hub", lang: "en", country: "brand-hub" });
sitesMap.set("ifu-register", { siteName: "ifu", lang: "en", country: "ifu" });

sitesMap.set("micro/cochlear-foundation", { siteName: "cochlear-foundation", lang: "en", country: "cof" });
sitesMap.set("cof", { siteName: "cochlear-foundation", lang: "en", country: "cof" });
sitesMap.set("soc", { siteName: "standard-of-care", lang: "en", country: "soc" });

/**
 * Extracts the country, site and language parameters from the path of a request.
 * Strip virtual path removes the virtual path component before a layoutService request is made, but
 * after the country, site and lang parameters are extracted from the virtual path.
 * @param {string} reqUrl full path from incoming request
 * @returns {object} `{siteName: string, lang: string, country: string, url: string}`
 *
 * - `siteName`: Site name e.g. `cochlear-au-en`
 * - `lang`: Mapped language code, e.g. `en` or `de-DE`
 * - `country`: Cochlear country code, or site suffix. `int` for Core, `global` for Global pages
 * - `url`: Path with leading slash, without the country and language, e.g. `/home`
 */
export default function resolveSite(reqUrl) {
  const match = reqUrl.match(
    /(^\/(ifu-register|brand-hub|cof|soc|micro\/cochlear-foundation))|(\/[a-z]{2}\/[a-z]{2}($|\/))|(\/[a-z]{2}($|\/))|\/(emea|global|apac|mdr|mri-us|oee|ciom)\/[a-z]{2}(-hans|-hant|-br)*($|\/)/,
  );

  // the part of the url that matches a key of sitesMap
  const virtualPath = match?.[0].replace(/^\/|\/$/g, "");

  if (!virtualPath || !sitesMap.has(virtualPath)) {
    return { ...sitesMap.get("default"), url: reqUrl };
  }

  let url = reqUrl.replace(`/${virtualPath}`, "");

  // prepend slash if not exists - necessary for paths that rewrite home items
  // E.g. /brand-hub -> brand
  if (url[0] !== "/") {
    url = `/${url}`;
  }

  return { ...sitesMap.get(virtualPath), url };
}
